@use "./variables";

@mixin respond($breakpoint) {
    @if $breakpoint==$viewport-xxlDesktop {
        @media only screen and (min-width: $viewport-xlDesktop) {
            @content;
        }
    }

    @if $breakpoint==$viewport-xlDesktop {
        @media only screen and (min-width: $viewport-desktop) and (max-width: $viewport-xlDesktop) {
            @content;
        }
    }

    @if $breakpoint==$viewport-desktop {
        @media only screen and (min-width: $viewport-tablet) and (max-width: $viewport-desktop) {
            @content;
        }
    }

    @if $breakpoint==$viewport-tablet {
        @media only screen and (min-width: $viewport-mobile) and (max-width: $viewport-tablet) {
            @content;
        }
    }

    @if $breakpoint==$viewport-mobile {
        @media only screen and (max-width: $viewport-mobile) {
            // background-color: red;
            @content;
        }
    }
}
