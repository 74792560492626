@import "../../style/sass/mixins";
@import "../../style/sass/variables";

.spinner {
    .anticon {
        font-size: 48px;
        // @include respond($viewport-mobile) {
        //     font-size: 90px;
        // }

        // @include respond($viewport-mobile) {
        //     font-size: 90px;
        // }
    }
}

.spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    &.isFullPage {
        overflow: hidden;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center; /*centers items on the line (the x-axis by default)*/
        align-items: center; /*centers items on the cross-axis (y by default)*/
    }

    &.isAppLoader {
        .ant-spin {
            color: black;
        }
    }
}
