@import "./style/sass/index.scss";

html {
    font-size: var($--default-font-size);
}

body {
    margin: 0;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;

    @include respond($viewport-mobile) {
        // background: blue;
        // font-size: 8px;
    }
}
