@import "../../style/sass/mixins";
@import "../../style/sass/variables";

.header {
    &__content {
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        &__brand-logo-img {
            @include respond($viewport-xxlDesktop) {
                width: 200px;
            }

            @include respond($viewport-xlDesktop) {
                width: 200px;
            }

            @include respond($viewport-desktop) {
                width: 200px;
            }

            @include respond($viewport-tablet) {
                width: 200px;
            }

            @include respond($viewport-mobile) {
                width: 200px;
            }

            img {
                width: 100%;
            }
        }

        &__settings {
            width: 2rem;
        }
    }
}

.affix {
    z-index: 2;

    .ant-affix {
        z-index: 2;
        background-color: rgb(240, 249, 246);
        box-shadow: rgb(89 95 98);
    }
}
