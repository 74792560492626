@import "../../style/sass/mixins";
@import "../../style/sass/variables";

.navigation-bar {
    position: fixed;
    width: 100%;
    &.top {
        top: 0;
    }

    &.bottom {
        bottom: 0;
    }

    &__group {
        .ant-segmented-item {
            border-radius: 0;
            border-top: 3px solid transparent;
            transition: ease-in-out 0.3s;

            &.ant-segmented-item-selected {
                border-radius: 0;
                border-top: 3px solid var(--navigation-bar-selected-item);
                -webkit-transition: ease-in-out 0.3s;
                -moz-transition: ease-in-out 0.3s;
                -ms-transition: ease-in-out 0.3s;
                -o-transition: ease-in-out 0.3s;
                transition: ease-in-out 0.3s;
            }
        }
        .wrapper-icon {
            display: grid;
            justify-content: center;

            @include respond($viewport-xxlDesktop) {
                padding: 2rem;
            }

            @include respond($viewport-xlDesktop) {
                padding: 2rem;
            }

            @include respond($viewport-desktop) {
                padding: 2rem;
            }

            @include respond($viewport-tablet) {
                padding: 1.5rem;
            }

            @include respond($viewport-mobile) {
                padding: 0.8rem;
            }
        }
    }

    .anticon {
        font-size: 32px;

        &.small {
            font-size: 32px;
        }
        &.medium {
            font-size: 48px;
        }
        &.large {
            font-size: 64px;
        }
    }
}
