html {
    --question-card-primary-color: rgb(0, 133, 124);
    --question-card-background-color: #fff;
    --question-card-hover-background-color: #fff;
    --question-card-border-color: rgb(0, 133, 124);
    --question-card-hover-border-color: rgb(0, 133, 124);
    --question-card-avatar-color: rgb(0, 133, 124);
    --question-card-avatar-background-color: #f0f9f6;
    --question-card-text-color: rgb(61, 72, 76);

    --question-editable-card-background-color: var(--question-card-avatar-background-color);
    --question-editable-card-border-color: var(--question-card-avatar-background-color);
    --question-editable-card-hover-border-color: var(--question-card-primary-color);
    --question-editable-card-avatar-color: var(--question-card-primary-color);
    --question-editable-card-avatar-background-color: #fff;
    --question-editable-card-text-color: rgb(61, 72, 76);
    --question-editable-card-focus-textarea-border-color: var(--question-card-primary-color);

    --question-editable-active-cancel-button-border-color: var(--question-card-primary-color);
    --question-editable-active-cancel-button-color: var(--question-card-primary-color);

    --question-editable-active-submit-button-backgroud-color: var(--question-card-primary-color);
    --question-editable-active-submit-button-border-color: var(--question-card-primary-color);
    --question-editable-active-submit-button-color: #fff;
}

.question-card {
    padding: 1rem;
    background-color: var(--question-card-background-color);
    border-radius: 0.4rem;
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--question-card-border-color);

    &.hoverable {
        cursor: pointer;
    }

    &.editable {
        --question-card-background-color: var(--question-editable-card-background-color);
        --question-card-border-color: var(--question-editable-card-border-color);
        --question-card-hover-border-color: var(--question-editable-card-hover-border-color);
        --question-card-avatar-color: var(--question-editable-card-avatar-color);
        --question-card-avatar-background-color: var(
            --question-editable-card-avatar-background-color
        );
        --question-card-text-color: var(--question-editable-card-text-color);

        &:hover {
            --question-card-avatar-background-color: var(
                --question-editable-card-avatar-background-color
            );
        }
    }

    &.submitted {
        --question-card-avatar-background-color: var(--question-card-background-color);

        opacity: 0.6;
        border-color: transparent;
        background-color: var(--question-card-hover-background-color);

        &:hover {
            border-color: transparent;
        }
    }

    &:hover {
        --question-card-avatar-background-color: var(--question-card-background-color);
        border-color: var(--question-card-hover-border-color);
        background-color: var(--question-card-hover-background-color);
    }

    &__header {
        display: flex;
        gap: 1rem;
        position: relative;
    }

    &__user {
        margin: 0;
        font-size: 0.7rem;

        @media (max-width: 1366px) {
            font-size: 0.5rem;
        }
    }

    &__label {
        font-size: 0.65rem;
        margin: 0;

        @media (max-width: 1366px) {
            font-size: 0.55rem;
        }
    }

    &__date {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 0.7rem;

        @media (max-width: 1366px) {
            font-size: 0.4rem;
        }
    }

    &__avatar {
        width: 2.5rem;
        height: 2.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        border-radius: 100%;
        color: var(--question-card-avatar-color);
        font-size: 1.2rem;
        font-weight: 500;
        background-color: var(--question-card-avatar-background-color);
    }

    &__question {
        margin-top: 0.6rem;
        margin-bottom: 0.4rem;
        color: var(--question-card-text-color);

        @media (max-width: 1366px) {
            font-size: 0.75rem;
        }
    }

    &__textarea {
        margin: 1rem 0;
        border-radius: 0.4rem;

        &:hover,
        &:focus {
            border-color: var(--question-editable-card-focus-textarea-border-color);
        }
    }

    &__actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        gap: 1rem;

        @media (max-width: 1366px) {
            flex-wrap: wrap-reverse;
            gap: 0.5rem;
        }

        @media (max-width: 1024px) {
            justify-content: center;
        }

        .question-btn {
            -ms-user-select: none;
            -webkit-user-select: none;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            height: 2.45rem;
            text-align: center;
            user-select: none;
            vertical-align: middle;

            @media (max-width: 1366px) {
                font-size: 0.725rem;
                height: 1.8rem;
            }

            &:hover,
            &:focus,
            &:active {
                color: var(--question-editable-active-cancel-button-color);
                border: 1px solid var(--question-editable-active-cancel-button-border-color);
            }

            &.submit-question {
                background-color: var(--question-editable-active-submit-button-backgroud-color);
                border: 1px solid var(--question-editable-active-submit-button-border-color);
                color: var(--question-editable-active-submit-button-color);

                &:hover,
                &:focus,
                &:active {
                    color: var(--question-editable-active-submit-button-color);
                    background-color: var(--question-editable-active-submit-button-backgroud-color);
                    border: 1px solid var(--question-editable-active-submit-button-border-color);
                }
            }
        }
    }
}
